@import "abstracts/_abstracts";
.Breadcrumbs {
	$block: &;
	position: relative;
	background-color: var(--colorFooterAccent);

	&-frame {
		display: flex;
		flex-wrap: wrap;
	}

	&-addon {
		margin: 0 var(--spaceMd) 0 0;
		width: 16.5rem;
		@include media('<=md') {
			width: 14.5rem;
		}

		@include media('<=sm') {
			width: calc(100% + var(--spaceMd) + var(--spaceMd));
			margin: -1px calc(var(--spaceMd) * -1) var(--spaceMd);
			order: 2;
		}
	}

	&-inner {
		display: flex;
		flex-wrap: wrap;;
		align-items: center;
		flex: 1;
		font-size: var(--textXs);
		padding: var(--spaceRg) 0;
	}

	&-item {
		&:before {
			color: var(--colorThemeAccent);
			margin: 0 var(--spaceXs);
			@include media('<=sm') {
				content: '❮';
				margin-left: 0;
			}
		}

		&:not(:first-child):before {
			@include media('>sm') {
				content: '›';
			}
		}

		&:not(:nth-last-child(2)) {
			@include media('<=sm') {
				display: none;
			}
		}

		a {
			text-decoration: none;
			color: var(--colorThemeAccent);

			&[href] {
				text-decoration: underline;
				transition: var(--animationBase);

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	&--modern {
		background-color: var(--colorBrandBg);

		#{$block}-inner {
			padding: var(--spaceMd) 0;
		}
	}
}